import { graphql, Link } from 'gatsby'
import React from 'react'
// import Layout from '../components/layout'
import parse, { domToReact } from 'html-react-parser';

import TOC from '../components/toc'
import * as Styles from '../styles/scss/templates/blog-post.module.scss'

interface IMarkdownRemark {
  frontmatter: {
    title: string;
  }
  tableOfContents: string;
  html: string;
}

interface IPostPageProps {
  data: {
    markdownRemark: IMarkdownRemark
  }
}

interface IPostPageState {
  post: IMarkdownRemark;
}

export default class PostPage extends React.Component<IPostPageProps, IPostPageState> {
  state = {
    post: this.props.data.markdownRemark
  };

  public render() {
    return (
      <div className={Styles.postContainer}>
        <div className="columns">
          <div className="column">
            <Link to="/">Home</Link>
            <h1 className="title">{this.state.post.frontmatter.title}</h1>
            {/* <h2 class="subtitle">
              A simple container to divide your page into <strong>sections</strong>, like the one you're currently reading
      </h2> */}
          </div>
        </div>
        <div className="columns">
          <div className={`column column is-four-fifths ` + Styles.postContent}>
            {/* dangerouslySetInnerHTML={{ __html: this.state.post.html }}> */}
            {parse(this.state.post.html)}
          </div>
          <nav className={`column`}>
            <TOC html={this.state.post.tableOfContents}>
              {/* {parse(this.state.post.tableOfContents)} */}
              {/* <div dangerouslySetInnerHTML={{ __html: post.tableOfContents }} /> */}
            </TOC>
          </nav>
        </div>
      </div>

    )
  }
}

export const query = graphql`
  query(
    $slug: String!
    $pathToSlugField: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
      }
      tableOfContents(
        pathToSlugField: $pathToSlugField
      )
    }
  }
`
