// extracted by mini-css-extract-plugin
export var button = "blog-post-module--button--13HYK";
export var isFocused = "blog-post-module--is-focused--qCcBy";
export var isActive = "blog-post-module--is-active--2IFMK";
export var navbarLink = "blog-post-module--navbar-link--3j93l";
export var isArrowless = "blog-post-module--is-arrowless--3kwNY";
export var isLoading = "blog-post-module--is-loading--VL63U";
export var spinAround = "blog-post-module--spinAround--1GXx1";
export var testLink = "blog-post-module--testLink--16Jtt";
export var navbarText = "blog-post-module--navbar-text--1rJSY";
export var navbar = "blog-post-module--navbar--2Ttv5";
export var isWhite = "blog-post-module--is-white--3GWFa";
export var navbarBrand = "blog-post-module--navbar-brand--1rDqV";
export var navbarItem = "blog-post-module--navbar-item--mLlVt";
export var navbarBurger = "blog-post-module--navbar-burger--1Buto";
export var navbarStart = "blog-post-module--navbar-start--13xLu";
export var navbarEnd = "blog-post-module--navbar-end--3lvco";
export var hasDropdown = "blog-post-module--has-dropdown--TFUxo";
export var navbarDropdown = "blog-post-module--navbar-dropdown--sO31W";
export var isBlack = "blog-post-module--is-black--pY8mE";
export var isLight = "blog-post-module--is-light--3EW1E";
export var isDark = "blog-post-module--is-dark--rK3nv";
export var isPrimary = "blog-post-module--is-primary--BRR02";
export var isLink = "blog-post-module--is-link--13THK";
export var isInfo = "blog-post-module--is-info--2jO5S";
export var isSuccess = "blog-post-module--is-success--36qgn";
export var isWarning = "blog-post-module--is-warning--3pVD3";
export var isDanger = "blog-post-module--is-danger--1oLH0";
export var container = "blog-post-module--container--3Q3v1";
export var hasShadow = "blog-post-module--has-shadow--2i85T";
export var isFixedBottom = "blog-post-module--is-fixed-bottom--26-sL";
export var isFixedTop = "blog-post-module--is-fixed-top--2R2e6";
export var hasNavbarFixedTop = "blog-post-module--has-navbar-fixed-top--wAsow";
export var hasNavbarFixedBottom = "blog-post-module--has-navbar-fixed-bottom--2kH-r";
export var navbarTabs = "blog-post-module--navbar-tabs--3hKkE";
export var navbarMenu = "blog-post-module--navbar-menu--2qtVr";
export var icon = "blog-post-module--icon--36BDA";
export var isExpanded = "blog-post-module--is-expanded--3d8iy";
export var isTab = "blog-post-module--is-tab--1flg-";
export var navbarContent = "blog-post-module--navbar-content--KdLI6";
export var navbarDivider = "blog-post-module--navbar-divider--3irhv";
export var isFixedBottomTouch = "blog-post-module--is-fixed-bottom-touch--3Yaw-";
export var isFixedTopTouch = "blog-post-module--is-fixed-top-touch--Dc8rD";
export var hasNavbarFixedTopTouch = "blog-post-module--has-navbar-fixed-top-touch--3nAGA";
export var hasNavbarFixedBottomTouch = "blog-post-module--has-navbar-fixed-bottom-touch--3ssil";
export var isSpaced = "blog-post-module--is-spaced--30ubm";
export var isTransparent = "blog-post-module--is-transparent--lqKaV";
export var isHoverable = "blog-post-module--is-hoverable--BkBW4";
export var hasDropdownUp = "blog-post-module--has-dropdown-up--1nOFR";
export var isBoxed = "blog-post-module--is-boxed--2gaCw";
export var isRight = "blog-post-module--is-right--37EPy";
export var isFixedBottomDesktop = "blog-post-module--is-fixed-bottom-desktop--3T7A6";
export var isFixedTopDesktop = "blog-post-module--is-fixed-top-desktop--3-U5z";
export var hasNavbarFixedTopDesktop = "blog-post-module--has-navbar-fixed-top-desktop--12lGn";
export var hasNavbarFixedBottomDesktop = "blog-post-module--has-navbar-fixed-bottom-desktop--3AAmP";
export var hasSpacedNavbarFixedTop = "blog-post-module--has-spaced-navbar-fixed-top--3JcVJ";
export var hasSpacedNavbarFixedBottom = "blog-post-module--has-spaced-navbar-fixed-bottom--1-w4y";
export var hero = "blog-post-module--hero--20yQP";
export var isFullheightWithNavbar = "blog-post-module--is-fullheight-with-navbar--2iPo3";
export var isSmall = "blog-post-module--is-small--1e5tK";
export var isMedium = "blog-post-module--is-medium--3rpft";
export var isLarge = "blog-post-module--is-large--3KKbo";
export var isHovered = "blog-post-module--is-hovered--QQhXs";
export var isText = "blog-post-module--is-text--2mh9n";
export var isGhost = "blog-post-module--is-ghost--rjB0z";
export var isInverted = "blog-post-module--is-inverted--3gjNk";
export var isOutlined = "blog-post-module--is-outlined--38I33";
export var isRounded = "blog-post-module--is-rounded--7yBCC";
export var isNormal = "blog-post-module--is-normal--1QWb_";
export var isFullwidth = "blog-post-module--is-fullwidth--1nfJ8";
export var isStatic = "blog-post-module--is-static--3EOE1";
export var buttons = "blog-post-module--buttons--14kjz";
export var areSmall = "blog-post-module--are-small--1JhTO";
export var areMedium = "blog-post-module--are-medium--1TUGN";
export var areLarge = "blog-post-module--are-large--2Lmm9";
export var hasAddons = "blog-post-module--has-addons--2jwXE";
export var isSelected = "blog-post-module--is-selected--1fzS-";
export var isCentered = "blog-post-module--is-centered--BuiDN";
export var iconText = "blog-post-module--icon-text--1CnWK";
export var column = "blog-post-module--column--7KikG";
export var columns = "blog-post-module--columns--3NyCr";
export var isMobile = "blog-post-module--is-mobile--NGFHa";
export var isNarrow = "blog-post-module--is-narrow--1uL4G";
export var isFull = "blog-post-module--is-full--21yLE";
export var isThreeQuarters = "blog-post-module--is-three-quarters--3boNL";
export var isTwoThirds = "blog-post-module--is-two-thirds--26uXt";
export var isHalf = "blog-post-module--is-half--31tU9";
export var isOneThird = "blog-post-module--is-one-third--1i1oz";
export var isOneQuarter = "blog-post-module--is-one-quarter--12g49";
export var isOneFifth = "blog-post-module--is-one-fifth--1nH8r";
export var isTwoFifths = "blog-post-module--is-two-fifths--2p0Tl";
export var isThreeFifths = "blog-post-module--is-three-fifths--3-HsX";
export var isFourFifths = "blog-post-module--is-four-fifths--2aw2c";
export var isOffsetThreeQuarters = "blog-post-module--is-offset-three-quarters--24JD2";
export var isOffsetTwoThirds = "blog-post-module--is-offset-two-thirds--oalZU";
export var isOffsetHalf = "blog-post-module--is-offset-half--2kDnn";
export var isOffsetOneThird = "blog-post-module--is-offset-one-third--2Euak";
export var isOffsetOneQuarter = "blog-post-module--is-offset-one-quarter--1J4Eg";
export var isOffsetOneFifth = "blog-post-module--is-offset-one-fifth--2eyxW";
export var isOffsetTwoFifths = "blog-post-module--is-offset-two-fifths--3ts8z";
export var isOffsetThreeFifths = "blog-post-module--is-offset-three-fifths--1sBKt";
export var isOffsetFourFifths = "blog-post-module--is-offset-four-fifths--2mZjs";
export var is0 = "blog-post-module--is-0--1d2Sy";
export var isOffset0 = "blog-post-module--is-offset-0--10isU";
export var is1 = "blog-post-module--is-1--1sI-2";
export var isOffset1 = "blog-post-module--is-offset-1--2Atzy";
export var is2 = "blog-post-module--is-2--27UBa";
export var isOffset2 = "blog-post-module--is-offset-2--3bM5f";
export var is3 = "blog-post-module--is-3--2pV3u";
export var isOffset3 = "blog-post-module--is-offset-3--wTvdZ";
export var is4 = "blog-post-module--is-4--W4EAX";
export var isOffset4 = "blog-post-module--is-offset-4--3bEDj";
export var is5 = "blog-post-module--is-5--1znk_";
export var isOffset5 = "blog-post-module--is-offset-5--2uX7t";
export var is6 = "blog-post-module--is-6--1uWPs";
export var isOffset6 = "blog-post-module--is-offset-6--Jq0GU";
export var is7 = "blog-post-module--is-7--1Go66";
export var isOffset7 = "blog-post-module--is-offset-7--3WIIr";
export var is8 = "blog-post-module--is-8--FIW80";
export var isOffset8 = "blog-post-module--is-offset-8--19MSv";
export var is9 = "blog-post-module--is-9--arO9B";
export var isOffset9 = "blog-post-module--is-offset-9--1HqJ8";
export var is10 = "blog-post-module--is-10--3hp6G";
export var isOffset10 = "blog-post-module--is-offset-10--c2DGy";
export var is11 = "blog-post-module--is-11--1x7ya";
export var isOffset11 = "blog-post-module--is-offset-11--_rCE-";
export var is12 = "blog-post-module--is-12--jeigK";
export var isOffset12 = "blog-post-module--is-offset-12--Rpy91";
export var isNarrowMobile = "blog-post-module--is-narrow-mobile--2uLZ7";
export var isFullMobile = "blog-post-module--is-full-mobile--XZa95";
export var isThreeQuartersMobile = "blog-post-module--is-three-quarters-mobile--1kxiI";
export var isTwoThirdsMobile = "blog-post-module--is-two-thirds-mobile--nzcYL";
export var isHalfMobile = "blog-post-module--is-half-mobile--6RhLx";
export var isOneThirdMobile = "blog-post-module--is-one-third-mobile--3odqP";
export var isOneQuarterMobile = "blog-post-module--is-one-quarter-mobile--2evfl";
export var isOneFifthMobile = "blog-post-module--is-one-fifth-mobile--3cmFw";
export var isTwoFifthsMobile = "blog-post-module--is-two-fifths-mobile--1Z6pQ";
export var isThreeFifthsMobile = "blog-post-module--is-three-fifths-mobile--1T62E";
export var isFourFifthsMobile = "blog-post-module--is-four-fifths-mobile--cZRgh";
export var isOffsetThreeQuartersMobile = "blog-post-module--is-offset-three-quarters-mobile--2JH7h";
export var isOffsetTwoThirdsMobile = "blog-post-module--is-offset-two-thirds-mobile--1BTS8";
export var isOffsetHalfMobile = "blog-post-module--is-offset-half-mobile--3_DY1";
export var isOffsetOneThirdMobile = "blog-post-module--is-offset-one-third-mobile--Kdvrq";
export var isOffsetOneQuarterMobile = "blog-post-module--is-offset-one-quarter-mobile--GO0nA";
export var isOffsetOneFifthMobile = "blog-post-module--is-offset-one-fifth-mobile--3jOVg";
export var isOffsetTwoFifthsMobile = "blog-post-module--is-offset-two-fifths-mobile--2rCA0";
export var isOffsetThreeFifthsMobile = "blog-post-module--is-offset-three-fifths-mobile--1AQVC";
export var isOffsetFourFifthsMobile = "blog-post-module--is-offset-four-fifths-mobile--3bMZG";
export var is0Mobile = "blog-post-module--is-0-mobile--3b5dS";
export var isOffset0Mobile = "blog-post-module--is-offset-0-mobile--yXzaB";
export var is1Mobile = "blog-post-module--is-1-mobile--2jr9J";
export var isOffset1Mobile = "blog-post-module--is-offset-1-mobile--17dmC";
export var is2Mobile = "blog-post-module--is-2-mobile--UeyGz";
export var isOffset2Mobile = "blog-post-module--is-offset-2-mobile--1iQFD";
export var is3Mobile = "blog-post-module--is-3-mobile--2X9nd";
export var isOffset3Mobile = "blog-post-module--is-offset-3-mobile--1MJLy";
export var is4Mobile = "blog-post-module--is-4-mobile--2xzx0";
export var isOffset4Mobile = "blog-post-module--is-offset-4-mobile--15p2h";
export var is5Mobile = "blog-post-module--is-5-mobile--1k7QU";
export var isOffset5Mobile = "blog-post-module--is-offset-5-mobile--18WgA";
export var is6Mobile = "blog-post-module--is-6-mobile--3ZiUu";
export var isOffset6Mobile = "blog-post-module--is-offset-6-mobile--Kfhuj";
export var is7Mobile = "blog-post-module--is-7-mobile--nMmq0";
export var isOffset7Mobile = "blog-post-module--is-offset-7-mobile--36sx0";
export var is8Mobile = "blog-post-module--is-8-mobile--1msR8";
export var isOffset8Mobile = "blog-post-module--is-offset-8-mobile--2zzyp";
export var is9Mobile = "blog-post-module--is-9-mobile--3kABe";
export var isOffset9Mobile = "blog-post-module--is-offset-9-mobile--1kDde";
export var is10Mobile = "blog-post-module--is-10-mobile--14nif";
export var isOffset10Mobile = "blog-post-module--is-offset-10-mobile--1Joya";
export var is11Mobile = "blog-post-module--is-11-mobile--2RE3G";
export var isOffset11Mobile = "blog-post-module--is-offset-11-mobile--35z3O";
export var is12Mobile = "blog-post-module--is-12-mobile--x-EmW";
export var isOffset12Mobile = "blog-post-module--is-offset-12-mobile--1A_mt";
export var isNarrowTablet = "blog-post-module--is-narrow-tablet--2SUmL";
export var isFullTablet = "blog-post-module--is-full-tablet--3zfMV";
export var isThreeQuartersTablet = "blog-post-module--is-three-quarters-tablet--2tWce";
export var isTwoThirdsTablet = "blog-post-module--is-two-thirds-tablet--3P6Az";
export var isHalfTablet = "blog-post-module--is-half-tablet--3HypP";
export var isOneThirdTablet = "blog-post-module--is-one-third-tablet--9SlvO";
export var isOneQuarterTablet = "blog-post-module--is-one-quarter-tablet--8itL4";
export var isOneFifthTablet = "blog-post-module--is-one-fifth-tablet--2DguX";
export var isTwoFifthsTablet = "blog-post-module--is-two-fifths-tablet--2f1GU";
export var isThreeFifthsTablet = "blog-post-module--is-three-fifths-tablet--3svJ9";
export var isFourFifthsTablet = "blog-post-module--is-four-fifths-tablet--p26V7";
export var isOffsetThreeQuartersTablet = "blog-post-module--is-offset-three-quarters-tablet--28Exx";
export var isOffsetTwoThirdsTablet = "blog-post-module--is-offset-two-thirds-tablet--3ClhT";
export var isOffsetHalfTablet = "blog-post-module--is-offset-half-tablet--7GEjK";
export var isOffsetOneThirdTablet = "blog-post-module--is-offset-one-third-tablet--1ik8t";
export var isOffsetOneQuarterTablet = "blog-post-module--is-offset-one-quarter-tablet--1PrZP";
export var isOffsetOneFifthTablet = "blog-post-module--is-offset-one-fifth-tablet--1-B1l";
export var isOffsetTwoFifthsTablet = "blog-post-module--is-offset-two-fifths-tablet--24zai";
export var isOffsetThreeFifthsTablet = "blog-post-module--is-offset-three-fifths-tablet--ouniB";
export var isOffsetFourFifthsTablet = "blog-post-module--is-offset-four-fifths-tablet--3D_mi";
export var is0Tablet = "blog-post-module--is-0-tablet--Lt-iZ";
export var isOffset0Tablet = "blog-post-module--is-offset-0-tablet--3Gb9d";
export var is1Tablet = "blog-post-module--is-1-tablet--36dpD";
export var isOffset1Tablet = "blog-post-module--is-offset-1-tablet--2p_Gd";
export var is2Tablet = "blog-post-module--is-2-tablet--2xTHi";
export var isOffset2Tablet = "blog-post-module--is-offset-2-tablet--9rb-d";
export var is3Tablet = "blog-post-module--is-3-tablet--2pHQB";
export var isOffset3Tablet = "blog-post-module--is-offset-3-tablet--1nH6t";
export var is4Tablet = "blog-post-module--is-4-tablet--2mY94";
export var isOffset4Tablet = "blog-post-module--is-offset-4-tablet--2Hma7";
export var is5Tablet = "blog-post-module--is-5-tablet--2-dO5";
export var isOffset5Tablet = "blog-post-module--is-offset-5-tablet--1fcHY";
export var is6Tablet = "blog-post-module--is-6-tablet--19ALa";
export var isOffset6Tablet = "blog-post-module--is-offset-6-tablet--1pFKZ";
export var is7Tablet = "blog-post-module--is-7-tablet--3TSQG";
export var isOffset7Tablet = "blog-post-module--is-offset-7-tablet--1URFp";
export var is8Tablet = "blog-post-module--is-8-tablet--LstcK";
export var isOffset8Tablet = "blog-post-module--is-offset-8-tablet--1-UHA";
export var is9Tablet = "blog-post-module--is-9-tablet--Uft7P";
export var isOffset9Tablet = "blog-post-module--is-offset-9-tablet--2ouJE";
export var is10Tablet = "blog-post-module--is-10-tablet--3yy67";
export var isOffset10Tablet = "blog-post-module--is-offset-10-tablet--xILNM";
export var is11Tablet = "blog-post-module--is-11-tablet--1V8aB";
export var isOffset11Tablet = "blog-post-module--is-offset-11-tablet--205lS";
export var is12Tablet = "blog-post-module--is-12-tablet--3WMWn";
export var isOffset12Tablet = "blog-post-module--is-offset-12-tablet--2fJL7";
export var isNarrowTouch = "blog-post-module--is-narrow-touch--3hz_I";
export var isFullTouch = "blog-post-module--is-full-touch--2nMMm";
export var isThreeQuartersTouch = "blog-post-module--is-three-quarters-touch--DcWzO";
export var isTwoThirdsTouch = "blog-post-module--is-two-thirds-touch--2xwiw";
export var isHalfTouch = "blog-post-module--is-half-touch--3fziq";
export var isOneThirdTouch = "blog-post-module--is-one-third-touch--qYmNE";
export var isOneQuarterTouch = "blog-post-module--is-one-quarter-touch--38Nwc";
export var isOneFifthTouch = "blog-post-module--is-one-fifth-touch--3RetA";
export var isTwoFifthsTouch = "blog-post-module--is-two-fifths-touch--1Tf0Z";
export var isThreeFifthsTouch = "blog-post-module--is-three-fifths-touch--3Pc8P";
export var isFourFifthsTouch = "blog-post-module--is-four-fifths-touch--3C2Sp";
export var isOffsetThreeQuartersTouch = "blog-post-module--is-offset-three-quarters-touch--y80av";
export var isOffsetTwoThirdsTouch = "blog-post-module--is-offset-two-thirds-touch--2cLM1";
export var isOffsetHalfTouch = "blog-post-module--is-offset-half-touch--1u5X_";
export var isOffsetOneThirdTouch = "blog-post-module--is-offset-one-third-touch--2R2kI";
export var isOffsetOneQuarterTouch = "blog-post-module--is-offset-one-quarter-touch--3r2gm";
export var isOffsetOneFifthTouch = "blog-post-module--is-offset-one-fifth-touch--3yQyb";
export var isOffsetTwoFifthsTouch = "blog-post-module--is-offset-two-fifths-touch--923v-";
export var isOffsetThreeFifthsTouch = "blog-post-module--is-offset-three-fifths-touch--1JQCG";
export var isOffsetFourFifthsTouch = "blog-post-module--is-offset-four-fifths-touch--IUh-n";
export var is0Touch = "blog-post-module--is-0-touch--3Ylrs";
export var isOffset0Touch = "blog-post-module--is-offset-0-touch--1e_K7";
export var is1Touch = "blog-post-module--is-1-touch--2B8pL";
export var isOffset1Touch = "blog-post-module--is-offset-1-touch--2Z9UU";
export var is2Touch = "blog-post-module--is-2-touch--2gebz";
export var isOffset2Touch = "blog-post-module--is-offset-2-touch--3jeA2";
export var is3Touch = "blog-post-module--is-3-touch--2WNdV";
export var isOffset3Touch = "blog-post-module--is-offset-3-touch--34roS";
export var is4Touch = "blog-post-module--is-4-touch--ckKvM";
export var isOffset4Touch = "blog-post-module--is-offset-4-touch--2O5w3";
export var is5Touch = "blog-post-module--is-5-touch--3olXC";
export var isOffset5Touch = "blog-post-module--is-offset-5-touch--21ynU";
export var is6Touch = "blog-post-module--is-6-touch--3FIc4";
export var isOffset6Touch = "blog-post-module--is-offset-6-touch--1-8BU";
export var is7Touch = "blog-post-module--is-7-touch--2xjkS";
export var isOffset7Touch = "blog-post-module--is-offset-7-touch--2ZtGy";
export var is8Touch = "blog-post-module--is-8-touch--2nVYL";
export var isOffset8Touch = "blog-post-module--is-offset-8-touch--s4t9L";
export var is9Touch = "blog-post-module--is-9-touch--1K86_";
export var isOffset9Touch = "blog-post-module--is-offset-9-touch--1hd7P";
export var is10Touch = "blog-post-module--is-10-touch--1AwNr";
export var isOffset10Touch = "blog-post-module--is-offset-10-touch--1uJFd";
export var is11Touch = "blog-post-module--is-11-touch--33LpO";
export var isOffset11Touch = "blog-post-module--is-offset-11-touch--1zd1A";
export var is12Touch = "blog-post-module--is-12-touch--3awZr";
export var isOffset12Touch = "blog-post-module--is-offset-12-touch--2FNa-";
export var isNarrowDesktop = "blog-post-module--is-narrow-desktop--3hab0";
export var isFullDesktop = "blog-post-module--is-full-desktop--35JST";
export var isThreeQuartersDesktop = "blog-post-module--is-three-quarters-desktop--1oJZ-";
export var isTwoThirdsDesktop = "blog-post-module--is-two-thirds-desktop--114cQ";
export var isHalfDesktop = "blog-post-module--is-half-desktop--WtrEb";
export var isOneThirdDesktop = "blog-post-module--is-one-third-desktop--1zVrR";
export var isOneQuarterDesktop = "blog-post-module--is-one-quarter-desktop--27_m2";
export var isOneFifthDesktop = "blog-post-module--is-one-fifth-desktop--2_n83";
export var isTwoFifthsDesktop = "blog-post-module--is-two-fifths-desktop--2BDwK";
export var isThreeFifthsDesktop = "blog-post-module--is-three-fifths-desktop--2Kg4M";
export var isFourFifthsDesktop = "blog-post-module--is-four-fifths-desktop--2JPA_";
export var isOffsetThreeQuartersDesktop = "blog-post-module--is-offset-three-quarters-desktop--3ArZU";
export var isOffsetTwoThirdsDesktop = "blog-post-module--is-offset-two-thirds-desktop--1X888";
export var isOffsetHalfDesktop = "blog-post-module--is-offset-half-desktop--2JO36";
export var isOffsetOneThirdDesktop = "blog-post-module--is-offset-one-third-desktop--2rpc3";
export var isOffsetOneQuarterDesktop = "blog-post-module--is-offset-one-quarter-desktop--3e1Eq";
export var isOffsetOneFifthDesktop = "blog-post-module--is-offset-one-fifth-desktop--3R_3h";
export var isOffsetTwoFifthsDesktop = "blog-post-module--is-offset-two-fifths-desktop--1DayX";
export var isOffsetThreeFifthsDesktop = "blog-post-module--is-offset-three-fifths-desktop--34Ssx";
export var isOffsetFourFifthsDesktop = "blog-post-module--is-offset-four-fifths-desktop--3XCRT";
export var is0Desktop = "blog-post-module--is-0-desktop--uaO9G";
export var isOffset0Desktop = "blog-post-module--is-offset-0-desktop--1df5A";
export var is1Desktop = "blog-post-module--is-1-desktop--3o9zd";
export var isOffset1Desktop = "blog-post-module--is-offset-1-desktop--2E0Uw";
export var is2Desktop = "blog-post-module--is-2-desktop--7pweW";
export var isOffset2Desktop = "blog-post-module--is-offset-2-desktop--2vl5f";
export var is3Desktop = "blog-post-module--is-3-desktop--3FdZ2";
export var isOffset3Desktop = "blog-post-module--is-offset-3-desktop--1SgU-";
export var is4Desktop = "blog-post-module--is-4-desktop--O6yup";
export var isOffset4Desktop = "blog-post-module--is-offset-4-desktop--eNr_y";
export var is5Desktop = "blog-post-module--is-5-desktop--3kU92";
export var isOffset5Desktop = "blog-post-module--is-offset-5-desktop--xn5r5";
export var is6Desktop = "blog-post-module--is-6-desktop--1KVTo";
export var isOffset6Desktop = "blog-post-module--is-offset-6-desktop--4Dk_i";
export var is7Desktop = "blog-post-module--is-7-desktop--1oezP";
export var isOffset7Desktop = "blog-post-module--is-offset-7-desktop--RRRfQ";
export var is8Desktop = "blog-post-module--is-8-desktop--3hP8C";
export var isOffset8Desktop = "blog-post-module--is-offset-8-desktop--ChdW5";
export var is9Desktop = "blog-post-module--is-9-desktop--2dP11";
export var isOffset9Desktop = "blog-post-module--is-offset-9-desktop--2Dlww";
export var is10Desktop = "blog-post-module--is-10-desktop--Bs4xv";
export var isOffset10Desktop = "blog-post-module--is-offset-10-desktop--3X5G_";
export var is11Desktop = "blog-post-module--is-11-desktop--ed4kK";
export var isOffset11Desktop = "blog-post-module--is-offset-11-desktop--1aWLd";
export var is12Desktop = "blog-post-module--is-12-desktop--3pj9s";
export var isOffset12Desktop = "blog-post-module--is-offset-12-desktop--F1e3M";
export var isGapless = "blog-post-module--is-gapless--2SjMV";
export var isMultiline = "blog-post-module--is-multiline--xydBW";
export var isVcentered = "blog-post-module--is-vcentered--1LH6H";
export var isDesktop = "blog-post-module--is-desktop--3DoIl";
export var isVariable = "blog-post-module--is-variable--2aLLX";
export var is0TabletOnly = "blog-post-module--is-0-tablet-only--2vT0E";
export var is1TabletOnly = "blog-post-module--is-1-tablet-only--2pv_Z";
export var is2TabletOnly = "blog-post-module--is-2-tablet-only--S_2Sm";
export var is3TabletOnly = "blog-post-module--is-3-tablet-only--3Ns_P";
export var is4TabletOnly = "blog-post-module--is-4-tablet-only--3JoUA";
export var is5TabletOnly = "blog-post-module--is-5-tablet-only--YXzDt";
export var is6TabletOnly = "blog-post-module--is-6-tablet-only--38JQe";
export var is7TabletOnly = "blog-post-module--is-7-tablet-only--21cmE";
export var is8TabletOnly = "blog-post-module--is-8-tablet-only--36EI3";
export var postContainer = "blog-post-module--postContainer--170gG";
export var postContent = "blog-post-module--postContent--11NtV";