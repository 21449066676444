import React from "react"
import * as Styles from '../styles/scss/templates/toc.module.scss'
import parse, { domToReact } from 'html-react-parser';
import { Element } from 'domhandler/lib/node';
/**
 * Reference: https://codepen.io/hakimel/pen/BpKNPg
 */

interface ITOCProps {
    html: string;
}
interface ITOCState {
    node: Element
}


function parseHtml(html: string): Element {

    const options = {
        replace: domNode => {
            const { name, attribs, children } = domNode;
            if (!attribs) {
                return;
            }

            if (name === 'a') {
                domNode.attribs.className='active';
                // console.dir(domNode, {depth: null});
            }

            // if (attribs.id === 'main') {
            //     return <h1 style={{ fontSize: 42 }}>{domToReact(children, options)}</h1>;
            // }

            // if (attribs.class === 'prettify') {
            //     return (
            //         <span style={{ color: 'hotpink' }}>
            //             {domToReact(children, options)}
            //         </span>
            //     );
            // }
        }
    };
    const node: Element = parse(html, options);
    // console.dir(node, { depth: null });
    return node;
}

export default class TOC extends React.Component<ITOCProps, ITOCState> {
    state = {
        node: parseHtml(this.props.html)
    };

    public render() {
        return (
            <div className={Styles.toc}>
                {/* {this.props.children} */}
                {this.state.node}
                <svg className={Styles.tocMarker} width="200" height="200" xmlns="http://www.w3.org/2000/svg">
                    <path stroke="#444" strokeWidth="3" fill="transparent" strokeDasharray="0, 0, 0, 1000" strokeLinecap="round" strokeLinejoin="round" transform="translate(-0.5, -0.5)" />
                </svg>
            </div>)
    }
}